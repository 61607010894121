var pawLibrary = {
	map: false,
	monthNames: ["Jan","Feb","Mar","Apr","May","June","Jul","Aug","Sept","Oct","Nov","Dec"],
	loading: "<div id=\"loader\" class=\"center\" style=\"width:100%\"><img src=\"./imgs/loader.gif\" alt=\"Loading...\"  /></div>",
	markers: [],
	offset: 0.8,
	dropdownMenus: function()
	{
		$('.parent > a').on('click', function(e) {
			var selected = $(this);
			var id = selected.attr('id');
			if(id != '') id = id.replace('showMenu','');

			if($('#subMenu' + id).length == 1)
			{
				e.preventDefault();
			}

			// Show
			if(selected.hasClass('highlight') == true) {
				// Hide
				selected.removeClass('highlight');
				$('.parent-sub').hide();
			} else {
				// Show
				selected.addClass('highlight');
				$('.parent-sub').hide();
				$('.parent a').removeClass('highlight');
				$('#subMenu' + id).fadeIn();
				$('#showMenu' + id).addClass('highlight');
			}
		});
	},
	search: function(term)
	{
		$('#searchresults-wrap').html('<div id="loading">Searching...</div>').load('ajax/search.inc.php?term=' + term);
	},
	validateForm: function()
	{
		var errors = 0;

		var name_txt = $('#name_txt');
		var email_txt = $('#email_txt');
		var message_txt = $('#message_txt');
		var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

		if(name_txt.val().length < 3)
		{
			name_txt.addClass('required_border');
			errors++;
		} else {
			name_txt.removeClass('required_border');
		}

		if( !filter.test(email_txt.val()) )
		{
			email_txt.addClass('required_border');
			errors++;
		} else {
			email_txt.removeClass('required_border');
		}

		if(message_txt.val().length < 3)
		{
			message_txt.addClass('required_border');
			errors++;
		} else {
			message_txt.removeClass('required_border');
		}

		return (errors == 0) ? true : false;

	},

	getTwitterProfilePic: function(elemId, username)
	{

		$.ajax({
			type: 'json',
		    url: '../ajax/getTwitter.inc.php?username=' + username,
		    beforeSend: function() {
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="./imgs/loader.gif" alt="'+username+'" class="loader" />');
		    },
		    success: function(data){
		    	var img = data[0].image;
		    	$('#comment' + elemId + ' .img-wrap').html('<img src="'+img+'" alt="'+username+'" class="profile" />');
		    }
		});

	},

	generateMap: function(elem,lat,lng, zoomLevel) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: zoomLevel,
		  center: myLatlng,
      scrollwheel: false,
			draggable: true,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		}

		this.map = new google.maps.Map(document.getElementById(elem), myOptions);
	},

	addMarker: function(lat, lng, location_title, location_add) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var contentString = '<div id="mapContent" class="infoMarker">'+
			'<p><strong>'+location_title+'</strong></p>'+
				'<p>'+location_add+'</p>'+
				'<a class="btn" href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: this.map,
			title: location_title
		});

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(this.map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});
	},
	centerMap: function() {
		var center = this.map.getCenter();
		this.map.setCenter();
	},

	setMap : function(elem, lat, lng, location_title, location_add, drag) {
		var myLatlng = new google.maps.LatLng(lat,lng);
		var myOptions = {
		  zoom: 14,
		  center: myLatlng,
      scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: drag
		}

		var map = new google.maps.Map(document.getElementById(elem), myOptions);

		var contentString = '<div id="mapContent">'+
			'<p><strong>'+location_title+'</strong></p>'+
				'<p>'+location_add+'</p>'+
				'<a class="btn" href="http://maps.google.co.uk/maps?daddr='+encodeURI(location_add)+'">Get Directions</a>' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
			content: contentString
		});

		var marker = new google.maps.Marker({
			position: myLatlng,
			map: map,
			title: location_title,
			draggable: drag
		});

		if(drag == true) {
			google.maps.event.addListener(marker, 'dragend', function() {
					var point = marker.position;
					if(lat_txt) lat_txt.value = point.$a;
					if(long_txt) long_txt.value = point.ab;

				});
		}

		google.maps.event.addListener(marker, 'click', function() {
		  infowindow.open(map,marker);
		});

		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});

	},

	setMapByGeocode : function(elemId, address, zoomlevel) {

		var mapOptions = {
		  zoom: zoomlevel,
          scrollwheel: false,
		  mapTypeId: google.maps.MapTypeId.ROADMAP,
		  draggable: false
		}

		var contentString = '<div id="mapContent">'+
				'<p>'+address+'</p>'+
				'<a href="http://maps.google.co.uk/maps?daddr='+encodeURI(address)+'">Get Directions</a> &raquo;' +
			'</div>';

		var infowindow = new google.maps.InfoWindow({
            content: contentString
         });

		var geocoder = new google.maps.Geocoder();
		var map = new google.maps.Map(document.getElementById(elemId), mapOptions);

		geocoder.geocode( {
			'address': address
				}, function(results, status) {
					  if (status == google.maps.GeocoderStatus.OK) {
						map.setCenter(results[0].geometry.location);
						var marker = new google.maps.Marker({
							map: map,
							position: results[0].geometry.location
						});

						google.maps.event.addListener(marker, 'click', function() {
						  infowindow.open(map,marker);
						});

					  }
					}
		);

	},

	mobileNav: function()
	{
		var mobileNavItems = $('#mobile-nav-items');
		var mobileBtn = $('#mobile-burger-btn');
		var pageBody = $('#page-wrap-inner');
		var secondMenu = mobileNavItems.find('ul.second');
		if( mobileBtn.length > 0 )
		{
			// Load Menu
			mobileBtn.click(function() {
				if(mobileBtn.hasClass('menu-open') == true) {
					pageBody.removeClass('push');
					mobileBtn.removeClass('menu-open');
				} else {
					pageBody.addClass('push');
					mobileBtn.addClass('menu-open');
				}
			});

			$('.triggerSubMenu').unbind().click(function(e) {
				var clicked = e.currentTarget.id.replace("showMenu","");
				var target = mobileNavItems.find('#subMenu' + clicked);
				
				if(target.hasClass('open') == false) {
					secondMenu.slideUp(function() {
						secondMenu.removeClass('open');
					});

					target.slideDown(function() {
						target.addClass('open');
					});
				} else {
					secondMenu.slideUp(function() {
						secondMenu.removeClass('open');
					});
				}
			});
		}

		$('#mobile-nav-inner-items').hcSticky({
			bottom:0
		});

	},

	dateSelector: function() {
		if($('.datepicker').length > 0)
		{
			var d = new Date();
			var today = d.getDate() + " " + this.monthNames[d.getMonth()] + " " + d.getFullYear().toString().substr(2,2);
			$(".datepicker").minical({
				trigger: '.datepicker',
				from: new Date(),
				initialize_with_date: false
			}).val('Starting ' + today);
		}
	},

	inlineEnquiries: function()
	{
		var enquiry_btn = $('#enquiry_btn');

		enquiry_btn.click(function(e) {
			var title = $('.productDetails h1').html();
			e.preventDefault();
			enquiry_btn.fancybox({
					fitToView	: false,
					autoSize	: true,
					type: 'ajax',
					padding: 0,
					href: 'frontend/view/inc/forms/enquiry.inc.php',
					beforeShow: function() {
						$('.fancybox-inner h3').html("Enquire About " + title);
        		$("body").css({'overflow-y':'hidden'});
					},
					afterClose: function() {
        		$("body").css({'overflow-y':'visible'});
					},
					afterShow: function() {
						var form = $('.fancybox-inner form');

						form.submit(function(e) {
							e.preventDefault();
							var loader = $('#loader');
							var data = {};
								data['name_txt'] = $('.fancybox-inner form #name_txt').val();
								data['email_txt'] = $('.fancybox-inner form #email_txt').val();
								data['telephone_txt'] = $('.fancybox-inner form #telephone_txt').val();
								data['message_txt'] = $('.fancybox-inner form #message_txt').val();
								data['pageName'] = title;
								data['list_txt'] = "";
								data['url'] = 'http://' + window.location.hostname + window.location.pathname;

							form.hide();
							loader.fadeIn();


							$.ajax({
								type: 'POST',
								url: './ajax/sendEnquiry.inc.php',
								data: data,
							}).done(function(response) {
								loader.hide();
								form.html(response).show();
							});

							return false;
						});
					}
				});


		});
	},

	onResize: function()
	{

	},

	setGallery: function()
	{

		$('#slideshow-inner').on('init', function(slick) {
			$('#rightBtn').trigger('click');
			setTimeout(function(){
				$('.loader').css('opacity', 0).css('zIndex', 0);
			}, 2000);
		}).slick({
			centerMode: true,
			nextArrow: '<span id="rightBtn" class="rightBtn"><i class="fa fa-angle-right"></i></span>',
			prevArrow: '<span id="leftBtn" class="leftBtn"><i class="fa fa-angle-left"></i></span>',
			speed: 300,
		  centerMode: true,
		  slidesToShow: 1,
		  variableWidth: true
		})

	},

	banners: function() {
		var banners = $('#banners');

		if(banners.length > 0) {
			banners.slick({
				dots: true,
			  infinite: true,
			  speed: 500,
			  fade: true,
				nextArrow: '<span id="rightBannerBtn" class="rightBannerBtn"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',
				prevArrow: '<span id="leftBannerBtn" class="leftBannerBtn"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
			  cssEase: 'linear',
        appendDots: '.banner-paging .inner-wrap',
				slide: '.item'
			});
		}
	},

	products: function() {

	},

	setMinorTabs: function() {
		if($('#minor-tabs').length > 0) {
			var tabs = $('#minor-tabs');
			var btns = tabs.find('li');
			var anchors = tabs.find('a');
			var current = 0;

			btns.unbind().click(function(e) {
				var selected = $(this).attr('id').replace("btn_", "");
				if(current != selected) {
					// selected tab
					btns.removeClass('selected');
					$('#btn_' + selected).addClass('selected');
					// selected textarea
					$('#item_' + current).addClass('hidden');
					$('#item_' + selected).fadeIn().removeClass('hidden').css("display","inline-block");
					// updated current
					current = selected;
				}
			});

		}
	},

	validateEmail: function(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
	},

	setTabs: function() {
		if($('#tabs').length > 0) {
			var width = $(window).width();
			var tabs = $('#tabs');
			var btns = tabs.find('li');
			var anchors = tabs.find('a');
			var current = 0;

			if(width > 930) {
				anchors.unbind().click(function(e) {
					e.preventDefault();
				});
				$('#item_' + current).removeClass('hidden');

				btns.unbind().click(function(e) {
					var selected = $(this).attr('id').replace("btn_", "");
					if(current != selected) {
						// selected tab
						btns.removeClass('selected');
						$('#btn_' + selected).addClass('selected');
						// selected textarea
						$('#item_' + current).addClass('hidden');
						$('#item_' + selected).fadeIn().removeClass('hidden').css("display","inline-block");
						// updated current
						current = selected;
					}
				});
			} else {
				anchors.unbind();
				$('.txt-item-wrap').addClass('hidden').attr('css');
			}
		}

	},

	jumpToLinks: function()
	{
		$('a[href^=#]').click(function(e){
    	var name = $(this).attr('href').substr(1);
    	var pos = $('#'+name).offset();
    	$('body').animate({ scrollTop: pos.top });
    	e.preventDefault();
  	});
	},

	insertParam: function(key, value, loc)
	{
		var params = false;

		if( loc == false )
		{
		    var loc = location.href;
		    	loc += '?cat_sel=&capacity_sel=&type_sel=';
		}

	    if(loc.indexOf("?") !== -1) {
	       loc = loc.split('?');
	       params = loc[1];
	       loc = loc[0];
	    }
	    loc = loc + '?';

	    if(params != false)
	    {
		    params = params.split('&');
		    var x;
		    for(x =0; x < params.length; x++)
		    {
		    	var tmp = params[x].split('=');
		    	if(tmp[0]==key) {
		    		loc += key + '=' + value + '&';
		    	} else {
		    		loc += tmp[0] + '=' + tmp[1] + '&';
		    	}
		    }
		    loc = loc.slice(0,-1);
		} else {
			loc += key + '=' + value;
		}

		return loc;
	},

	updateProductURL: function(url)
	{
		window.history.pushState(null, null, url);
	},

	isValidEmailAddress: function(emailAddress) {
    var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(emailAddress);
	},
	populateItems: function(items, paging) {
		var number = 1;
		var loadMoreBtn = $('#load-more-items-btn');
		$.each(items, function(key,row) {
			var formattedDate = new Date(row.date);
			var html_item = "<a href=\"" + row.url + "\" class=\"social-channel\"><i class=\"fa fa-" + row.type + "\"></i></a>";
						if(row.video != '') {
							html_item += "<div class=\"video-wrap\">";
								html_item += "<iframe src=\"https://www.facebook.com/plugins/video.php?href=" + encodeURI(row.video) + "&show_text=0\" style=\"border:none;overflow:hidden\" scrolling=\"no\" frameborder=\"0\" allowTransparency=\"true\" allowFullScreen=\"true\"></iframe>";
							html_item += "</div>";
						} else if(row.img != "") {
							html_item += "<div class=\"img-wrap\">";
								html_item += "<img src=\"" + row.img + "\" />";
							html_item += "</div>";
						} else {
							html_item += "<div class=\"hr\"></div>";
						}
						
						html_item += "<div class=\"txt-wrap\">";
							html_item += "<h4>" + pawLibrary.monthNames[formattedDate.getMonth()] + " " + formattedDate.getDate() + pawLibrary.nth(formattedDate.getDate()) + ", " + formattedDate.getFullYear() + "</h4>";
							if((row.msg != "") && (row.msg != undefined)) {
								html_item += "<p>" + row.msg + "</p>";
							}
						html_item += "</div>";
			
			var item = document.createElement('div');
					item.innerHTML = html_item;
			var attr = document.createAttribute("class");
					attr.value = "item";
					if(number > paging) {
						attr.value += " hidden";
					}
					item.setAttributeNode(attr); // attach classname
					attr = document.createAttribute("data-social-type");
					attr.value = row.type;
					item.setAttributeNode(attr);

			number++;
			salvattore.appendElements(grid, [item]);

			if($('#grid .hidden').length > 0) {
				loadMoreBtn.removeClass('hidden');
			}
		});
	},
	nth: function(d) {
		if(d>3 && d<21) return 'th';
		switch (d % 10) {
					case 1:  return "st";
					case 2:  return "nd";
					case 3:  return "rd";
					default: return "th";
			}
	},
	showMediaFeed: function(facebook, paging) {
		var items = [];
		var target = $('#grid');
		var grid = document.querySelector('#grid');
		var loading = $('#loader');
		var loadMoreBtn = $('#load-more-items-btn');
		

		function dynamicSort(property) {
			var sortOrder = 1;
			if(property[0] === "-") {
					sortOrder = -1;
					property = property.substr(1);
			}
			return function (a,b) {
					var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
					return result * sortOrder;
			}
		}
		if(sessionStorage.getItem("news") === null) {
			loading.removeClass("hidden");

			// SET PREPOPULATED NEWS
			var pawNews = $('#news-items li');
			$.each(pawNews, function(key,row) {
				var row = $(row);
				var item = {
					img: row.find('img').attr('src'), 
					url: row.find('a').attr('href'),
					msg: row.find('.txt-wrap').html(),
					date: row.attr('data-date'),
					type: "native",
					video: ''
				};
				item.msg += " &hellip; <a href=\"" + item.url + "\">Read Story</a>";
				items.push(item);
			});

			
				
					
					
					// FINSISH
					loading.addClass("hidden");
					items.sort(dynamicSort("-date"));
					sessionStorage.setItem('news', JSON.stringify(items));
					loading.addClass("hidden");
					pawLibrary.populateItems(items, paging);
					
				
			
		} else {
			var stored_news = sessionStorage.getItem('news');
					stored_news =  JSON.parse(stored_news);
			pawLibrary.populateItems(stored_news, paging);
		}

		loadMoreBtn.click(function() {
			var added = 0;
			var hidden = $('#grid .hidden').length;
			while((added < paging) && (hidden > 0)) {
				var cols = $('#grid .column');
				$.each(cols, function(key,col) {
					item = $(col).find('.hidden');
					$(item[0]).removeClass('hidden');
					added++;
					hidden = $('#grid .hidden').length;
				});
			}
			if(hidden == 0) {
				loadMoreBtn.addClass('hidden');
			}
		});

	},

	linkedin: function(token) {
		$.ajax({
			url: 'https://api.linkedin.com/v1/people/~'
		}).done(function(response) {
		});

	},

	setTestimonialsItems: function() {
		$('#testimonials-about-items').slick({
			infinite: true,
			speed: 300,
			slidesToShow: 3,
			slidesToScroll: 1,
			nextArrow: '#testimonial-next',
			prevArrow: '#testimonial-prev',
			responsive: [
				{
					breakpoint: 1140,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 750,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
	},
	timeline: function() {
		var events_pagers = $('#timeline-pager');
		var events = $('#timeline-events');
		var slideshows = $('#timeline .cycle-slideshow');

		events_pagers.on('cycle-next cycle-prev', function(e, opts) {
			var index = opts.currSlide;
			console.log('INDEX == ', index);
			slideshows.not(this).cycle('goto', index);
		});
		events_pagers.find('li').click(function(e) {
			var index = e.currentTarget.dataset.timelineEvent;
			slideshows.cycle('goto', index);
		});




	},
	setImgThumbs: function() {
		var total = $('#thumbs-product-img-gallery').attr('data-total-images');
		var thumbs = (total > 2) ? 3 : 2;
		var width = $('#main-product-img-gallery').width();
		var maxWidth = width / thumbs;
		if(total == 2) {
				maxWidth += 7;
		}
		$('.thumb-item').css('maxWidth', maxWidth + "px");
	},
	productImages: function() {      
			var slideshows = $('.cycle-slideshow').on('cycle-next cycle-prev', function(e, opts) {
					// advance the other slideshow
					slideshows.not(this).cycle('goto', opts.currSlide);
			});

			$('#thumbs-product-img-gallery .cycle-slide').click(function(){
					var index = $('#thumbs-product-img-gallery').data('cycle.API').getSlideIndex(this);
					slideshows.cycle('goto', index);
			});
	},
	init: function()
	{
		pawLibrary.mobileNav();
		pawLibrary.inlineEnquiries();
		pawLibrary.banners();
		//pawLibrary.jumpToLinks();
		pawLibrary.dropdownMenus();


		if($('#tabs').length > 0) {
			var tabs = $('#tabs-btn span');
			var tabsContent = $('.tab-content-inner');
			tabsContent.addClass('hidden');
			$('#tabs-btn span:first').addClass('selected');
			$('.tab-content-inner:first').removeClass('hidden');
			tabs.click(function() {
				var selected = $(this).attr('data-tab-btn');
				var tabeContentText = $('#tab' + selected + '-txt .col:nth-child(2)');
				tabs.removeClass('selected');
				$('*[data-tab-btn="' + selected + '"]').addClass('selected');
				//
				tabsContent.hide().addClass('hidden');
				tabeContentText.hide();
				$('#tab' + selected + '-txt').removeClass('hidden').show();
				tabeContentText.fadeIn();
			});

			var topLevel = $('a.toggle');
			topLevel.click(function(e) {
		  	e.preventDefault();
		    var $this = $(this);
				topLevel.removeClass('active');
		    if ($this.next().hasClass('show')) {
		        $this.next().removeClass('show');
		        $this.next().slideUp(350);
		    } else {
		        $this.parent().parent().find('li .inner').removeClass('show');
						$this.parent().parent().find('li .inner').slideUp(350);
		        $this.next().toggleClass('show');
		        $this.next().slideToggle(350);
						$($this[0]).addClass('active');
		    }
			});
		}

		if($('#gallery').length > 0) {
			pawLibrary.productGallery();
		}

		if($('#filter_project_select').length > 0) {
			var project_sel = $('#filter_project_select');
			var title = project_sel.find('.title');
			title.click(function(e) {
				if(project_sel.hasClass('active') == true) {
					project_sel.removeClass('active');
				} else {
					project_sel.addClass('active');
				}
			});
		}

		if($('#spec-btns').length > 0) {
			var spec_tabs = $('#spec-btns li');
			var spec_content = $('.spec-items-inner');
			spec_tabs.click(function() {
				var selected = $(this).attr('id').replace("tab-btn-", "");
				spec_tabs.removeClass('selected');
				$('#tab-btn-' + selected).addClass('selected');
				//
				spec_content.hide();
				$('#spec-content-' + selected).fadeIn();
			});
		}

		var fancyBox = $('.fancybox');
		if(fancyBox.length > 0) {
			fancyBox.fancybox({
				padding: 0
			});
		}

		if($('#mobile-submenu').length > 0 ) {
			$('#mobile-submenu').change(function() {
				var selected = $(this).val();
				var base = $('base').attr('href');
				window.location = base + selected.replace("./", "");
			});
		}

		if( $('.filterItems').length > 0 )
		{
			$('.filterItems').on('change', function() {
				pawLibrary.productSearch();
			});
		}

		var base = $('base').attr('href');
		$('#mobile-sub-menu').on('change', function() {
			var value = $(this).val().replace("./", "");
			window.location = base + value;
		});

		if($('#location_txt').length > 0) {
			pawLibrary.searchSuppliers();
		}

		if($('#testimonials-about-items').length > 0) {
			pawLibrary.setTestimonialsItems();
		}

		$('#request').fancybox({
			afterLoad: function() {
				var url = window.location.href;
						url = url.split('#');
						url = url[0];

				var tmp = $('#product-title');

				if(tmp.length > 0) {
					$('#url_txt').val(url);
					$('#title_txt').val(tmp.html());
				}
			},
			onComplete: function( instance, slide ) {
				var loading = $('#loader');
				var form = $('#enquiry-frm');

				var name_txt = form.find('#name_txt');
				var email_txt = form.find('#email_txt');
				var msg_txt = form.find('#msg_txt');

				var name_err = form.find('#error-name');
				var email_err = form.find('#error-email');
				var msg_err = form.find('#error-msg');

				$('#send_msg_btn').click(function(e) {
					e.preventDefault();
					var errors = false;

					// validation
					if(name_txt.val() == '') {

						name_txt.addClass('error-input');
						name_err.fadeIn();
						errors = true;
					} else {
						name_txt.removeClass('error-input');
						name_err.hide();
					}
					if(!pawLibrary.isValidEmailAddress(email_txt.val())) {
						email_txt.addClass('error-input');
						email_err.fadeIn();
						errors = true;
					} else {
						email_txt.removeClass('error-input');
						email_err.hide();
					}
					if(msg_txt.val() == '') {
						msg_txt.addClass('error-input');
						msg_err.fadeIn();
						errors = true;
					} else {
						msg_txt.removeClass('error-input');
						msg_err.hide();
					}

					// sent
					if(errors == false) {
						loading.removeClass('hide').fadeIn();
						form.hide();
						var formData = $('#enquiry-frm').serialize();
						$.ajax({
							url: './ajax/sendEnquiry.inc.php?init=sendEnquiry',
							data: formData,
							method: 'POST'
						}).done(function(response) {
							$('#loader').html("Your message has been sent");
						});
					}
				});
			}
		});

	}

}


$(window).load(function() {
	pawLibrary.init();
});

$(window).ready(function() {
	if($('#timeline').length > 0) {
		pawLibrary.timeline();
	}
	if($('#thumbs-product-img-gallery').length > 0) {
		pawLibrary.productImages();
		pawLibrary.setImgThumbs();
	}
});

$(window).resize(function(){
	$('#main-product-img-gallery,#thumbs-product-img-gallery').cycle('reinit');
	if($('#thumbs-product-img-gallery').length > 0) {
			pawLibrary.setImgThumbs();
	}

});